import React from "react";
import CountUp from "react-countup";

function About() {
  return (
    <div>
      <main id="main">
        {/* ======= About Section ======= */}
        <section id="about" className="about">
          <div className="container">
            <div className="section-title">
              <h2>ABOUT</h2>
              <p>I am an engineer graduate in computer science with professional experience in web development and digital marketing. 16 years of educational academic background. Innovative thinker, flexible, hard and smart worker, and learning new software skills in the IT sector. Currently, I am seeking job in web development. I can able to join the job Immediately.</p>
            </div>
            <div className="row">
              <div className="col-lg-4" data-aos="fade-right">
                <img src="assets/img/ashok.jpg" className="img-fluid" alt="" />
              </div>
              <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
                <h3 style={{ color: 'black' }} className="resume-title">Role and Responsibilities</h3>
                {/* <p className="fst-italic">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.
                </p> */}
                <div className="row">
                  <div className="col-lg-12">
                    <ul style={{ lineHeight: '20px' }}>
                      <br></br>
                      <li><i class="bi bi-chevron-right"></i>Meet clients to identify their needs and liaise regularly with them</li>
                      <li><i class="bi bi-chevron-right"></i>Draw up detailed website specifications</li>
                      <li><i class="bi bi-chevron-right"></i>Present initial design ideas to clients</li>
                      <li><i class="bi bi-chevron-right"></i>Edit the pre-designed free and paid web templates</li>
                      <li><i class="bi bi-chevron-right"></i>Design and develop the website as per the client requirement </li>
                      <li><i class="bi bi-chevron-right"></i>Edit content and re-design the web pages</li>
                      <li><i class="bi bi-chevron-right"></i>Proofread content and make changes where necessary</li>
                      <li><i class="bi bi-chevron-right"></i>Test the website to ensure it's working</li>
                      <li><i class="bi bi-chevron-right"></i>Work with other web developers and graphic designers</li>
                      <li><i class="bi bi-chevron-right"></i>Register web domain names and organize the hosting of the website</li>
                      <li><i class="bi bi-chevron-right"></i>Hand the completed website over to the client and provide technical support</li>
                      <li><i class="bi bi-chevron-right"></i>Digital Marketing Execution Management</li>
                      <li><i class="bi bi-chevron-right"></i>Project and Team Management</li>
                    </ul>
                  </div>
                </div>
                {/* <div className="row">
                  <h6 style={{ textDecoration: 'underline' }} ><b>TECHNICAL SKILLS:</b></h6>
                  <div className="col-lg-12">
                    <ul>
                      <li><i className="bi bi-chevron-right"></i> <strong>Development:</strong> <span>HTML, CSS, SCSS, Bootstrap, JavaScript, jQuery, JSON, XML, React JS</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>Design:</strong> <span>Adobe Photoshop</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>IDE:</strong> <span>Visual Studio Code</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>Code Management:</strong> <span>Git and GitHub</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>Report Tools:</strong> <span>Microsoft Office</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>Languages:</strong> <span>English and Tamil</span></li>
                    </ul>
                  </div>
                  <div className="col-lg-6">
                    <ul>
                      <li><i className="bi bi-chevron-right"></i> <strong>Age:</strong> <span>30</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>Degree:</strong> <span>Master</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>PhEmailone:</strong> <span>email@example.com</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>Freelance:</strong> <span>Available</span></li>
                    </ul>
                  </div>
                </div> */}
                {/* <p>
                  Officiis eligendi itaque labore et dolorum mollitia officiis optio vero. Quisquam sunt adipisci omnis et ut. Nulla accusantium dolor incidunt officia tempore. Et eius omnis.
                  Cupiditate ut dicta maxime officiis quidem quia. Sed et consectetur qui quia repellendus itaque neque. Aliquid amet quidem ut quaerat cupiditate. Ab et eum qui repellendus omnis culpa magni laudantium dolores.
                </p> */}
              </div>
            </div>
          </div>
        </section>
        {/* ======= Role and Responsibilities Section ======= */}
        {/* <section id="randr" className="about">
          <div className="container">
            <div className="section-title">
              <h2>Role and Responsibilities</h2>
            </div>
            <div className="row">
              <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
                <div className="row">
                  <div className="col-lg-12">
                    <ul style={{ lineHeight: '20px' }}>
                      <br></br>
                      <li><i class="bi bi-chevron-right"></i>Meet clients to identify their needs and liaise regularly with them</li>
                      <li><i class="bi bi-chevron-right"></i>Draw up detailed website specifications</li>
                      <li><i class="bi bi-chevron-right"></i>Present initial design ideas to clients</li>
                      <li><i class="bi bi-chevron-right"></i>Design the website's visual imagery and ensure it's in line with company branding policy or the requirements of the client</li>
                      <li><i class="bi bi-chevron-right"></i>Design and develop the website as per the client requirement </li>
                      <li><i class="bi bi-chevron-right"></i>Edit content, debug code and re-design the web pages</li>
                      <li><i class="bi bi-chevron-right"></i>Proofread content and make changes where necessary</li>
                      <li><i class="bi bi-chevron-right"></i>Write well designed, scalable, testable and efficient codes</li>
                      <li><i class="bi bi-chevron-right"></i>Test the website to ensure it's working</li>
                      <li><i class="bi bi-chevron-right"></i>Work with other web specialists, including web developers and graphic designers</li>
                      <li><i class="bi bi-chevron-right"></i>Register web domain names and organize the hosting of the website</li>
                      <li><i class="bi bi-chevron-right"></i>Hand the completed website over to the client and provide technical support</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* ======= Skills Section ======= */}
        <section id="skills" className="skills section-bg">
          <div className="container">
            <div className="section-title">
              <h2>SKILLS</h2>
              {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
            </div>
            <div className="row skills-content">
              <div className="col-lg-3">
                <ul>
                  <li><strong>Frontend</strong></li>
                  <li><strong>Frameworks</strong></li>
                  <li><strong>Libraries</strong></li>
                  <li><strong>CMS</strong></li>
                  <li><strong>Design Tool</strong></li>
                  <li><strong>IDE</strong></li>
                  <li><strong>Report Tool</strong></li>
                  <li><strong>VCS</strong></li>
                  <li><strong>Code Manage</strong></li>
                  <li><strong>Languages</strong></li>
                </ul>
              </div>
              <div className="col-lg-9">
                <ul style={{ listStyleType: 'none' }}>
                  <li><span>: HTML, CSS, SCSS, JavaScript</span></li>
                  <li><span>: Bootstrap</span></li>
                  <li><span>: React JS, jQuery</span></li>
                  <li><span>: WordPress</span></li>
                  <li><span>: Adobe Photoshop</span></li>
                  <li><span>: Visual Studio Code</span></li>
                  <li><span>: Microsoft Office</span></li>
                  <li><span>: Git</span></li>
                  <li><span>: Git Hub</span></li>
                  <li><span>: Tamil and English</span></li>
                </ul>
              </div>
              {/* <div className="col-lg-6" data-aos="fade-up">
                <div className="progress">
                  <span className="skill">HTML <i className="val">100%</i></span>
                  <div className="progress-bar-wrap">
                    <ProgressBar now={100} />
                    <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"><ProgressBar now={100} /></div>
                  </div>
                </div>
                <div className="progress">
                  <span className="skill">CSS <i className="val">90%</i></span>
                  <div className="progress-bar-wrap">
                    <ProgressBar now={90} />
                    <div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
                <div className="progress">
                  <span className="skill">JavaScript <i className="val">75%</i></span>
                  <div className="progress-bar-wrap">
                    <ProgressBar now={75} />
                    <div className="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                <div className="progress">
                  <span className="skill">PHP <i className="val">80%</i></span>
                  <div className="progress-bar-wrap">
                    <ProgressBar now={80} />
                    <div className="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
                <div className="progress">
                  <span className="skill">WordPress/CMS <i className="val">90%</i></span>
                  <div className="progress-bar-wrap">
                    <ProgressBar now={90} />
                    <div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
                <div className="progress">
                  <span className="skill">Photoshop <i className="val">55%</i></span>
                  <div className="progress-bar-wrap">
                    <ProgressBar now={55} />
                    <div className="progress-bar" role="progressbar" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        {/* ======= Facts Section ======= */}
        <section id="facts" className="facts">
          <div className="container">
            <div className="section-title">
              <h2>EDUCATION</h2>
              {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
            </div>
            <div className="row no-gutters">
              <div className="col-lg-4 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up">
                <div className="count-box">
                  <i className="bi bi-percent"></i>
                  <CountUp end={72} duration={5} />
                  <p><strong>Engineering in Computer Science</strong><br />Anna University</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="100">
                <div className="count-box">
                  <i className="bi bi-percent"></i>
                  <CountUp end={72} duration={5} />
                  <p><strong>H.S.C</strong><br />Tamil Nadu
                    School Board</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="200">
                <div className="count-box">
                  <i className="bi bi-percent"></i>
                  <CountUp end={77} duration={5} />
                  <p><strong>S.S.L.C</strong><br />Tamil Nadu
                    School Board</p>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="300">
                <div className="count-box">
                  <i className="bi bi-people"></i>
                  <CountUp end={32} duration={3} />
                  <p><strong>Hard Workers</strong> rerum asperiores dolor</p>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default About;
