import React from "react";
import { useTypewriter, Cursor } from 'react-simple-typewriter';

function Home() {
  const [text] = useTypewriter(
    {
      words: ['ReactJS Developer', 'Frontend Developer', 'Web Developer', 'UI UX Developer'],
      loop: 100,
      typeSpeed: 50,
      deleteSpeed: 20,
    }
  );
  return (
    <div>
      {/* ======= Hero Section ======= */}
      <section id="hero" className="d-flex flex-column justify-content-center align-items-center">
        <div className="hero-container" data-aos="fade-in">
          <h1>ASHOKKUMAR G D</h1>
          <p>I am {''}
            <span>{text}
              <Cursor />
            </span>
          </p>
        </div>
      </section>
    </div>
  );
}

export default Home;
