import React from "react";

function Footer() {
    return (
        <div>
            {/* ======= Footer ======= */}
            <footer id="footer">
                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong><span>{new Date().getFullYear()}</span>.</strong>
                    </div>
                    <div className="credits">
                        Developed by <a href="https://ashokkumargd.digidrips.com/">ASHOKKUMAR G D</a>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
