import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

function Navbar() {

  const [isOpen, setIsOpen] = useState(false);
  // const [isClassAdded, setIsClassAdded] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
    // setIsClassAdded(!isClassAdded);
  };
  // useEffect(() => {
  // Add or remove class to/from body element based on state
  //   if (isClassAdded) {
  //     document.body.classList.add('mobile-nav-active');
  //   } else {
  //     document.body.classList.remove('mobile-nav-active');
  //   }
  // }, [isClassAdded]);

  return (
    <div>
      <nav className="mobile-toggle-container">
        <div className="navbar-brand">
          <img src="assets/img/ashok.jpg" alt="" style={{ width: '50px', height: '50px' }} />
        </div>
        <div className={`navbar-menu ${isOpen ? 'is-active' : ''}`}>
          <div className="navbar-start">
            <ul>
              <li className="navbar-item"><NavLink onClick={toggleMenu} style={({ isActive }) => { return { color: isActive ? 'white' : '' } }} to="/" ><span>HOME</span></NavLink></li>
              <li className="navbar-item"><NavLink onClick={toggleMenu} style={({ isActive }) => { return { color: isActive ? 'white' : '' } }} to="/about" ><span>ABOUT</span></NavLink></li>
              <li className="navbar-item"><NavLink onClick={toggleMenu} style={({ isActive }) => { return { color: isActive ? 'white' : '' } }} to="/employment" ><span>EMPLOYMENT</span></NavLink></li>
              <li className="navbar-item"><NavLink onClick={toggleMenu} style={({ isActive }) => { return { color: isActive ? 'white' : '' } }} to="/projects" ><span>PROJECTS</span></NavLink></li>
              <li className="navbar-item"><NavLink onClick={toggleMenu} style={({ isActive }) => { return { color: isActive ? 'white' : '' } }} to="/contact" ><span>CONTACT</span></NavLink></li>
            </ul>
            <div className="text-center">
              <a target="_blank" href="https://ashokkumargd.digidrips.com/Resume/Resume%20-%20Ashokkumar%20G%20D.pdf">
                <button type="download">RESUME <i className='bx bxs-download'></i></button>
              </a>
              <hr></hr>
            </div>
          </div>
        </div>
        <button className={`mobile-toggle-button ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </button>
      </nav>
      <header id="header">
        <div className="d-flex flex-column">
          <div className="profile">
            <img src="assets/img/ashok.jpg" alt="" className="img-fluid rounded-circle" />
            <h1 className="text-light"><a href="/">ASHOKKUMAR G D</a></h1>
            <div className="text-center">
              <a target="_blank" href="https://ashokkumargd.digidrips.com/Resume/Resume%20-%20Ashokkumar%20G%20D.pdf">
                <button type="download">RESUME <i className='bx bxs-download'></i></button>
              </a>
            </div>
            <div className="social-links mt-3 text-center">
              <a target="_blank" href="https://www.linkedin.com/in/ashokkumar-g-d/" className="linkedin"><i className="bx bxl-linkedin"></i></a>
              <a target="_blank" href="https://github.com/ashokkumargd/" className="github"><i className="bx bxl-github"></i></a>
            </div>
          </div>
          <nav id="navbar" className="nav-menu navbar" >
            <ul>
              <li><NavLink onClick={toggleMenu} style={({ isActive }) => { return { color: isActive ? 'white' : '' } }} to="/" ><i className="bx bx-home"></i> <span>HOME</span></NavLink></li>
              <li><NavLink onClick={toggleMenu} style={({ isActive }) => { return { color: isActive ? 'white' : '' } }} to="/about" ><i className="bx bx-user"></i> <span>ABOUT</span></NavLink></li>
              <li><NavLink onClick={toggleMenu} style={({ isActive }) => { return { color: isActive ? 'white' : '' } }} to="/employment" ><i className="bx bx-book-content"></i> <span>EMPLOYMENT</span></NavLink></li>
              <li><NavLink onClick={toggleMenu} style={({ isActive }) => { return { color: isActive ? 'white' : '' } }} to="/projects" ><i className="bx bx-server"></i> <span>PROJECTS</span></NavLink></li>
              <li><NavLink onClick={toggleMenu} style={({ isActive }) => { return { color: isActive ? 'white' : '' } }} to="/contact" ><i className="bx bx-envelope"></i> <span>CONTACT</span></NavLink></li>
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
}

export default Navbar;
